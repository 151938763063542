(function () {

    'use strict';

    class BillingWorkflowCtrl {
        constructor(
            $mdPanel, $scope, $compile, moment, BillingWorkflowService, $state, mnWebSocket, system, $q, configService, $mdDialog, $element, $translate, frontDeskService, $mdToast) {
            this.$q = $q;
            this.state = $state;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.element = $element;
            this.translate = $translate;
            this.service = BillingWorkflowService;
            this.configService = configService;
            this.scope = $scope;
            this.frontDeskService = frontDeskService;
            this.toast = $mdToast;

            this.list = [];
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;


            this.filters = {};
            this.dateTimeFormat = system['date_format'].naive;

            this.views = [];
            this.selectedView = {};
            this.columns = this.service.getColumnConfiguration();
            this.getData = this.getData.bind(this);
            this.onPaginate = this.onPaginate.bind(this);

        }

        static get $inject() {
            return [
                '$mdPanel', '$scope', '$compile', 'moment', 'BillingWorkflowService', '$state', 'mnWebSocket', 'system', '$q', 'configService', '$mdDialog', "$element", "$translate", "frontDeskService", "$mdToast"];
        }

        $onInit() {
            this.buttons = {
                TODAY: "today",
                MONTH: "this_month",
            }

            this.paginationLabel = {
                page: this.translate['instant']('page'),
                rowsPerPage: this.translate['instant']('rowsPerPage'),
                of: this.translate['instant']('of')
            };
            this.options = [5, 15, 20, 50, 100];
            this.toggle = false;
            this.handleGlobalSearch = _.mnDelay(this.getData, 400);
            this.query = {
                page: 1,
                search_all: "",
                limit: 20,
                columns: this.columns
            }
            this.setInterval(localStorage.getItem("billing-workflow-filter") || 'TODAY');

            this.refreshDataCall();
            //todo next this.ws.sub("extranet.HealthCareServiceOrder.notify", 'billing-workflow', (data) => {
            //     this.refreshDataCall();
            // });
            this.scope.$on("$destroy", () => this.onDestroy())
        }

        onDestroy() {
            this.ws.unsub("extranet.HealthCareServiceOrder.notify", 'service-order-workflow');
        }

        setInterval(interval) {
            this.interval = interval;
            this.intervalChanged();
            this.getData();
        }

        intervalChanged() {
            if (this.interval) localStorage.setItem("billing-workflow-filter", this.interval);

            switch (this.interval) {
                case "TODAY":
                    this.today();
                    break;
                case "MONTH":
                    this.month();
                    break;
                default:
                    this.clear();
            }
        }

        clear() {
            this.query.start_date = this.query.end_date = null;
        }

        today() {
            this.query.start_date = this.query.end_date = this.moment().format(this.dateFormat);
        }

        month() {
            this.query.start_date = this.moment().startOf("month").format(this.dateFormat);
            this.query.end_date = this.moment().endOf("month").format(this.dateFormat);
        }

        refreshDataCall() {
            this.promise = this.service.getBillingWorkflowItems(this.query)
                .then(data => {
                    this.list = data['list'];
                    this.total = data['length'];
                    if (this.total < ((this.query.page - 1) * this.query.limit)) {
                        this.query.page = 1;
                    }
                });
        }

        countColumns() {
            return this.service.columnConfiguration.length + 1;
        }


        getData() {
            this.refreshDataCall();
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }

        handleToggle(event) {
            this.toggle = !this.toggle;
        }

        handleInvoice(item) {
            if (item.organization) {
                this.dialog.show(require('billing/dialogs/invoice-beneficiary-choice/invoice-beneficiary-choice')).then(data => {
                    if (data === 'ORGANIZATION') {
                        this.state.go("app.billing.invoice-form", {
                            invoiceId: null,
                            invoiceCart: this.handleInvoiceCart(item),
                            beneficiary: item.organization,
                            beneficiaryType: 'ORGANIZATION'
                        });
                    } else {
                        this.state.go("app.billing.invoice-form", {
                            invoiceId: null,
                            invoiceCart: this.handleInvoiceCart(item),
                            beneficiary: item.patient,
                            beneficiaryType: 'PATIENT'
                        });
                    }
                });
            } else {
                this.state.go("app.billing.invoice-form", {
                    invoiceId: null,
                    invoiceCart: this.handleInvoiceCart(item),
                    beneficiary: item.patient,
                    beneficiaryType: 'PATIENT'
                });
            }


        }

        handleInvoiceCart(item) {
            return _.reduce(['visit',
                'delivery_form',
                'treatment_plan',
                'medical_care_plan',
                'dental_consultation',
                'contract_instance',
                'health_care_service_order'], (acc, curr) => {
                acc[curr] = _.filter([item], (e) => {
                    return _.get(e, 'cls') == curr;
                })
                return acc;
            }, {})

        }

    }

    module.exports = BillingWorkflowCtrl;

})();
